import Asset from 'types/asset';
import AssetFilter from 'types/filters/assetFilter';
import { PaginationResult } from 'types/paginationResult';
import axios from 'utils/axios';

export default class AssetService {
    async getAssets(assetFilter: AssetFilter): Promise<PaginationResult<Asset>> {
        try {
            const body = {
                page: assetFilter.page,
                recordsPerPage: assetFilter.recordsPerPage,
                paired: assetFilter.paired,
                dateFrom: assetFilter.dateFrom,
                dateTo: assetFilter.dateTo,
                searchString: assetFilter.searchString,
                orderBy: assetFilter.orderBy,
                orderAscending: assetFilter.orderAscending,
                tag: assetFilter.tag
            };
            const result = await axios.post<PaginationResult<Asset>>(`/GetAssets`, body);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch asset list');
        }
    }

    async addAsset(asset: Asset, tenantId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/AddAsset`, {
                id: asset.id,
                name: asset.name,
                frequency: asset.frequency,
                acceleration: asset.acceleration,
                tenantId: tenantId,
                tags: asset.tags
            });
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to add asset');
        }
    }

    async editAsset(asset: Asset): Promise<boolean> {
        try {
            const response = await axios.post(`/EditAsset`, asset);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to edit asset');
        }
    }

    async deleteAsset(assetId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/DeleteAsset`, assetId);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to delete asset');
        }
    }
}
