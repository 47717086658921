import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    CardContent,
    Chip,
    debounce,
    Divider,
    Drawer,
    FormControl,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// halogList
// import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import AddIcon from '@mui/icons-material/Add';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { EnhancedTableHeadProps, HeadCell, ArrangementOrder } from 'types';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Halog from 'types/halog';
import HalogService from 'services/HalogService';
import HalogEdit from './halogEdit';
import HalogAdd from './halogAdd';
import { useCallback, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import useConfig from 'hooks/useConfig';
import HalogListMobile from './halogListMobile';
import Utilities from 'utils/utilities';
import { startOfDay } from 'date-fns/startOfDay';
import { endOfDay } from 'date-fns/endOfDay';
import ShowDevice from 'types/enums/showDevices';
import StepperDatePicker from 'ui-component/StepperDatePicker';
import HalogFilter from 'types/filters/halogFilter';
import { dispatch } from 'store';
import { startSnackbar } from 'store/slices/snackbar';
import { v4 as uuidv4 } from 'uuid';
import Tag from 'types/tag';
import TagService from 'services/TagService';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        label: 'DEVICE_NAME',
        align: 'left'
    },
    {
        id: 'identifier',
        numeric: false,
        label: 'IDENTIFIER',
        align: 'left'
    },
    {
        id: 'macAddress',
        numeric: false,
        label: 'MAC_ADDRESS',
        align: 'left'
    },
    {
        id: 'assetName',
        numeric: false,
        label: 'ASSET_NAME',
        align: 'left'
    },
    {
        id: 'paired',
        numeric: false,
        label: 'PAIRED_STATUS',
        align: 'center'
    },
    {
        id: 'pairDate',
        numeric: false,
        label: 'PAIR_DATE',
        align: 'right'
    },
    {
        id: 'tags',
        numeric: false,
        label: 'Tags',
        align: 'right'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface AssetListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected
}: AssetListEnhancedTableHeadProps) {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {t(headCell.label)}
                        </TableCell>
                    ))}
                {numSelected <= 0 && (
                    <TableCell sortDirection={false} align="right">
                        {t('ACTIONS')}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| HALOG LIST ||============================== //

const HalogList = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const { t } = useTranslation();
    const { locale } = useConfig();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const utilities = new Utilities();

    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('name');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [searchText, setSearchText] = useState<string>('');
    const [rows, setRows] = useState<Halog[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [halogToEdit, setHalogToEdit] = useState<Halog | undefined>(undefined);
    const [halogToAdd, setHalogToAdd] = useState<Halog | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [pairedHalog, setPairedHalog] = useState<ShowDevice>(ShowDevice.ALL);
    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);
    const [fliterDialogModalOpen, setFliterDialogModalOpen] = useState(false);
    const enumValues = Object.keys(ShowDevice).filter((key) => isNaN(Number(key)));
    const [tagFilterList, setTagFilterList] = useState<Tag[]>();
    const [tagSelected, setTagSelected] = useState<Tag | null>();
    const halogService = new HalogService();
    const tagService = new TagService();

    useEffect(() => {
        (async () => {
            setTagFilterList(await tagService.getTags(user!.tenantId));
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            if (user) {
                const halogFilter = getHalogFilter();
                await fetchRow(halogFilter);
            }
        })();
    }, [user, page, rowsPerPage, pairedHalog, dateFrom, dateTo, searchText, order, orderBy, tagSelected]);

    const getHalogFilter = () => {
        const halogFilter: HalogFilter = {
            dateFrom: dateFrom ? startOfDay(new Date(dateFrom!)) : null,
            dateTo: dateTo ? endOfDay(new Date(dateTo!)) : null,
            orderAscending: order?.toString() === 'asc',
            orderBy: orderBy,
            page: page + 1,
            paired: pairedHalog,
            recordsPerPage: rowsPerPage,
            searchString: searchText,
            tag: tagSelected!
        };
        return halogFilter;
    };

    const fetchRow = useCallback(
        debounce(async (halogFilter: HalogFilter) => {
            setIsLoading(true);
            const paginatedHalog = await halogService.getHalogList(halogFilter);
            setIsLoading(false);
            setRows(paginatedHalog.items);
            setTotalElements(paginatedHalog.totalCount);
        }, 700),
        []
    );

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newString = event?.target.value;
        setSearchText(newString || '');
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedId = rows.map((n) => n.name);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const onAdd = async (halog: Halog) => {
        if (halog && utilities.isNotNullOrEmpty(halog.identifier)) {
            setHalogToAdd(undefined);
            if (halog) {
                const response = await halogService.addHalog(halog, user?.tenantId!);
                if (response) {
                    const halogFilter = getHalogFilter();
                    await fetchRow(halogFilter);
                    setTagFilterList(await tagService.getTags(user!.tenantId));
                    dispatch(startSnackbar(t('DEVICE_ADDED_SUCCESSFULLY'), 'success'));
                }
            }
        } else {
            dispatch(startSnackbar(t('IDENTIFIER_IS_MANDATORY'), 'error'));
        }
    };

    const onSave = async (halog: Halog) => {
        setHalogToEdit(undefined);
        const response = await halogService.editHalog(halog);
        if (response) {
            const halogFilter = getHalogFilter();
            await fetchRow(halogFilter);
            setTagFilterList(await tagService.getTags(user!.tenantId));
            dispatch(startSnackbar(t('DEVICE_EDITED_SUCCESSFULLY'), 'success'));
        }
    };

    const onDelete = async (halog: Halog) => {
        setHalogToEdit(undefined);
        const result = await halogService.deleteHalog(halog.id);
        if (result) {
            const halogFilter = getHalogFilter();
            await fetchRow(halogFilter);
            dispatch(startSnackbar(t('DEVICE_DELETED_SUCCESSFULLY'), 'success'));
        } else {
            dispatch(startSnackbar(t('ERROR_DURING_DELETE_DEVICE'), 'error'));
        }
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleChangeOrder = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setOrderBy(value);
    };

    const handleChangeOrderMethod = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setOrder(value === 'asc' ? 'asc' : 'desc');
    };

    const handleChangeShowDevice = (event: SelectChangeEvent<typeof ShowDevice[keyof typeof ShowDevice]>) => {
        const selectedValue = event.target.value as keyof typeof ShowDevice;
        setPairedHalog(ShowDevice[selectedValue]);
    };

    const setDefaultFilters = () => {
        setOrder('asc');
        setOrderBy('name');
        setDateFrom(null);
        setDateTo(null);
        setPairedHalog(ShowDevice.ONLY_PAIRED);
    };

    const handleTag = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setTagSelected(tagFilterList?.find((tag) => tag.id === value));
        if (value === 'All') {
            setTagSelected(undefined);
        }
    };

    return (
        <MainCard title={t('DEVICES')} content={false}>
            <CardContent>
                <Grid container spacing={1} justifyContent="space-between" sx={{ mb: '1vh' }}>
                    <Drawer
                        anchor="right"
                        open={fliterDialogModalOpen}
                        onClose={() => setFliterDialogModalOpen(false)}
                        PaperProps={{
                            sx: { width: { xs: '75%', md: '50%', lg: '25%' } }
                        }}
                    >
                        <Grid container direction="column" spacing={2} sx={{ p: '15px' }}>
                            <Grid item justifyContent="center" textAlign="center">
                                <Grid container direction="row" alignItems="center">
                                    <Typography textAlign="center" variant="h5">
                                        {t('ADVANCED_FILTERS')}
                                    </Typography>
                                    <Tooltip title={t('CLOSE')}>
                                        <IconButton
                                            sx={{ marginLeft: 'auto', p: 0 }}
                                            onClick={() => {
                                                setFliterDialogModalOpen(false);
                                            }}
                                        >
                                            <HighlightOffIcon color="error" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormGroup>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>{t('PAIRED_STATUS')}</InputLabel>
                                        <Select
                                            label={t('PAIRED_STATUS')}
                                            onChange={handleChangeShowDevice}
                                            value={ShowDevice[pairedHalog]}
                                        >
                                            {enumValues.map((key) => (
                                                <MenuItem value={key} key={key}>
                                                    {t(key)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            {(pairedHalog === ShowDevice.ONLY_PAIRED || pairedHalog === ShowDevice.ALL) && (
                                <Grid item>
                                    <Grid item>
                                        <StepperDatePicker
                                            initialDate={dateFrom}
                                            finalDate={dateTo}
                                            onChange={(newValue) => {
                                                setDateFrom(newValue.initialDate);
                                                setDateTo(newValue.finalDate);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Divider sx={{ mb: 1, mt: 2, mx: '16px' }} />
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <InputLabel>{t('ORDER_BY')}</InputLabel>
                                    <Select
                                        label={t('ORDER_BY')}
                                        name="orderFieldName"
                                        onChange={handleChangeOrder}
                                        sx={{ color: 'white' }}
                                        value={orderBy}
                                    >
                                        <MenuItem value="name">{t('DEVICE_NAME')}</MenuItem>
                                        <MenuItem value="macAddress">{t('MAC_ADDRESS')}</MenuItem>
                                        <MenuItem value="identifier">{t('IDENTIFIER')}</MenuItem>
                                        {(pairedHalog === ShowDevice.ONLY_PAIRED || pairedHalog === ShowDevice.ALL) && (
                                            <MenuItem value="assetName">{t('ASSET_NAME')}</MenuItem>
                                        )}
                                        {(pairedHalog === ShowDevice.ONLY_PAIRED || pairedHalog === ShowDevice.ALL) && (
                                            <MenuItem value="pairDate">{t('PAIR_DATE')}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <InputLabel>{t('ORDER_METHOD')}</InputLabel>
                                    <Select
                                        label={t('ORDER_METHOD')}
                                        name="isDescending"
                                        onChange={handleChangeOrderMethod}
                                        sx={{ color: 'white' }}
                                        value={order}
                                    >
                                        <MenuItem value="asc">{t('ASCENDING')}</MenuItem>
                                        <MenuItem value="desc">{t('DESCENDING')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    startIcon={<CloseIcon fontSize="large" />}
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setDefaultFilters();
                                        setFliterDialogModalOpen(false);
                                    }}
                                >
                                    {t('REMOVE_FILTERS')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Drawer>

                    <Grid item lg={12} xs={12} container spacing={2}>
                        <Grid lg={4} md={4} item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder={mobileDevice ? t('SEARCH') : t('SEARCH_DEVICE')}
                                value={searchText}
                            />
                        </Grid>

                        {tagFilterList && (
                            <Grid
                                lg={2}
                                md={2}
                                item
                                xs={4}
                                sx={{
                                    marginRight: { md: 'auto' }
                                }}
                            >
                                <FormControl fullWidth>
                                    <InputLabel> {t('Tag')}</InputLabel>
                                    <Select
                                        label={t('Tag')}
                                        name="tags"
                                        size="small"
                                        onChange={handleTag}
                                        sx={{ color: 'white' }}
                                        defaultValue="All"
                                        value={tagSelected?.name}
                                    >
                                        <MenuItem value="All" key="All">
                                            {t('ALL')}
                                        </MenuItem>
                                        {tagFilterList!.map((tag) => (
                                            <MenuItem value={tag.id} key={tag.id}>
                                                {tag.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        <Grid
                            lg={4}
                            md={4}
                            xs={2}
                            item
                            sx={{ display: 'flex', justifyContent: mobileDevice ? 'space-around' : 'flex-end' }}
                        >
                            <Grid
                                item
                                lg={2}
                                md={2}
                                xs={windowWidth < 400 ? 3 : 4}
                                sx={{
                                    display: 'flex',
                                    justifyContent: windowWidth < 400 ? 'flex-start' : 'flex-end',
                                    textAlign: 'right'
                                }}
                            >
                                <Stack direction="row" spacing={-1}>
                                    <Tooltip title={t('FILTERS')}>
                                        <IconButton onClick={() => setFliterDialogModalOpen(true)}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            {user?.isSystemAdmin && (
                                <Grid item xs={1} sx={{ textAlign: 'right', paddingTop: 0.9 }}>
                                    <Tooltip title={t('ADD_DEVICE')}>
                                        <IconButton
                                            sx={{ backgroundColor: theme.palette.primary.main }}
                                            className="addButton"
                                            size="small"
                                            onClick={() =>
                                                setHalogToAdd({
                                                    id: uuidv4(),
                                                    name: '',
                                                    assetName: '',
                                                    pairDate: '',
                                                    identifier: '',
                                                    macAddress: '',
                                                    tenantId: '',
                                                    tags: []
                                                })
                                            }
                                        >
                                            <AddIcon sx={{ color: '#fff', fontSize: '17px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {rows ? (
                <>
                    {mobileDevice ? (
                        <HalogListMobile halogList={rows} onEdit={(row) => setHalogToEdit(row)} />
                    ) : (
                        <TableContainer sx={{ pl: '2px', pr: '2px' }}>
                            <Table
                                sx={{ minWidth: 750, '& .MuiTableCell-root': { padding: '2px 8px' } }}
                                aria-labelledby="tableTitle"
                                stickyHeader
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    selected={selected}
                                />
                                {!isLoading ? (
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            /** Make sure no display bugs if row isn't an OrderData object */
                                            if (typeof row === 'number') return null;
                                            const isItemSelected = isSelected(row.name);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                        >
                                                            {row.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                        >
                                                            {row.identifier}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                        >
                                                            {utilities.formatMacAddress(row.macAddress)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                        >
                                                            {row.assetName ? row.assetName : '-'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                                                textAlign: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            {row.pairDate && (
                                                                <BluetoothConnectedIcon
                                                                    sx={{
                                                                        fontSize: '1.3rem',
                                                                        marginY: '2px'
                                                                    }}
                                                                    color="secondary"
                                                                />
                                                            )}
                                                            {!row.pairDate && (
                                                                <BluetoothDisabledIcon
                                                                    sx={{ fontSize: '1.3rem', marginY: '2px' }}
                                                                    color="error"
                                                                />
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                                                float: 'right'
                                                            }}
                                                        >
                                                            {row.pairDate ? utilities.formatDate(row.pairDate, locale) : '-'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        align="right"
                                                        id={labelId}
                                                        scope="row"
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        {row.tags.map((value) => (
                                                            <Chip color="secondary" key={value.id} label={value.name} size="small" />
                                                        ))}
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ pr: 3, '& .MuiTableCell-root': { padding: '4px 8px' } }}>
                                                        <Tooltip title={t('EDIT')}>
                                                            <IconButton color="secondary" size="large" onClick={() => setHalogToEdit(row)}>
                                                                <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                ) : (
                                    <SkeletonBody numHeaders={7} showAction />
                                )}
                            </Table>
                        </TableContainer>
                    )}
                </>
            ) : (
                <Skeleton variant="rectangular" width={210} height={118} />
            )}
            {/* table pagination */}
            <TablePagination
                sx={{
                    ...(mobileDevice && {
                        display: 'flex',
                        '& .MuiTablePagination-actions': {
                            marginLeft: 0
                        },
                        '& .MuiInputBase-root': {
                            marginRight: 1
                        },
                        '& .MuiIconButton-root': {
                            padding: '6px'
                        }
                    })
                }}
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('ROWS_PER_PAGE')}
            />

            <HalogEdit halog={halogToEdit} onSave={onSave} onDelete={onDelete} onClose={() => setHalogToEdit(undefined)} />
            <HalogAdd halog={halogToAdd} onAdd={onAdd} onClose={() => setHalogToAdd(undefined)} />
        </MainCard>
    );
};

export default HalogList;
