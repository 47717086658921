import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { differenceInDays } from 'date-fns/differenceInDays';
import { getDaysInMonth } from 'date-fns/getDaysInMonth';
import { isValid } from 'date-fns/isValid';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConfig from 'hooks/useConfig';
import { SelectedDate } from 'types/SelectedDate';
import Utilities from 'utils/utilities';

type FielNameType = 'initialDate' | 'finalDate' | 'month' | 'year';

interface StepperDatePickerProps {
    disabled?: boolean;
    isMonthly?: boolean;
    disablePast?: boolean;
    initialDate: Date | null;
    finalDate: Date | null;
    maxDate?: number | null;
    onChange: (selectedDate: SelectedDate) => void;
    onError?: (error: boolean) => void;
}

const StepperDatePicker: FC<StepperDatePickerProps> = memo((props) => {
    const { disabled, isMonthly = false, disablePast = false, maxDate = null, initialDate, finalDate, onChange, onError } = props;
    const { t } = useTranslation();

    const { locale } = useConfig();

    const [isStep, setIsStep] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<SelectedDate>({ initialDate: null, finalDate: null });
    const [openInitialDate, setOpenInitialDate] = useState<boolean>(false);
    const [openFinalDate, setOpenFinalDate] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const timerRef = useRef<number>();
    const delay = isMonthly ? 200 : 1000;

    const handleOpenInitialDate = () => {
        setIsStep(true);
        setOpenInitialDate(true);
    };

    const handleOpenFinalDate = () => {
        setIsStep(true);
        setOpenFinalDate(true);
    };

    const handleCloseInitialDate = () => {
        setOpenInitialDate(false);
    };

    const handleCloseFinalDate = () => {
        setOpenFinalDate(false);
    };

    const handleChange = (newDate: Date | null, fieldName: FielNameType) => {
        if (newDate && isValid(newDate) && newDate.getFullYear() >= 1970) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            let day: number;
            let month: number;
            let year: number;

            let initialDate: Date | null = selectedDate.initialDate ? selectedDate.initialDate : null;
            let finalDate: Date | null = selectedDate.finalDate ? selectedDate.finalDate : null;

            switch (fieldName) {
                case 'month':
                    day = 1;
                    month = new Date(newDate).getMonth();
                    year = selectedDate.finalDate ? new Date(selectedDate.finalDate).getFullYear() : new Date(newDate).getFullYear();

                    initialDate = new Date(year, month, day);
                    finalDate = null;
                    if (isStep) {
                        setOpenInitialDate(false);
                        setOpenFinalDate(true);
                    }
                    break;
                case 'year':
                    day = selectedDate.initialDate ? getDaysInMonth(selectedDate.initialDate) : getDaysInMonth(newDate);
                    month = selectedDate.initialDate ? new Date(selectedDate.initialDate).getMonth() : new Date(newDate).getMonth();
                    year = new Date(newDate).getFullYear();

                    initialDate = new Date(year, month, 1);
                    finalDate = new Date(year, month, day);
                    if (isStep) {
                        setOpenFinalDate(false);
                    }
                    break;
                case 'initialDate':
                    initialDate = new Date(newDate);
                    finalDate = null;
                    if (isStep) {
                        setOpenInitialDate(false);
                        setOpenFinalDate(true);
                    }
                    break;
                case 'finalDate':
                    finalDate = new Date(newDate);
                    if (isStep) {
                        setOpenFinalDate(false);
                    }
                    break;
            }

            setSelectedDate({ initialDate: initialDate, finalDate: finalDate });

            const hasError = checkError(initialDate, finalDate);
            onError && onError(hasError);

            timerRef.current = window.setTimeout(() => {
                if (!hasError) {
                    onChange({ initialDate: initialDate, finalDate: finalDate });
                }
            }, delay);
        }

        setIsStep(false);
    };

    const checkError = (initialDate: Date | null, finalDate: Date | null) => {
        if (!initialDate || !finalDate) return false;

        if (disablePast) {
            if (differenceInDays(initialDate, new Date(new Date().getFullYear(), new Date().getMonth(), 1)) < 0) {
                setError('THE_SELECTED_PERIOD_HAS_PASSED');
                return true;
            }
        }

        if (maxDate) {
            if (differenceInDays(finalDate, initialDate) > maxDate) {
                setError(`THE_SELECTED_PERIOD_MUST_BE_LESS_THAN_DAYS/:${maxDate}`);
                return true;
            }
        }
        if (differenceInDays(finalDate, initialDate) < 0) {
            setError('SELECT_A_VALID_PERIOD');
            return true;
        }
        setError(null);
        return false;
    };

    useEffect(() => {
        setSelectedDate({ initialDate: initialDate, finalDate: finalDate });
    }, [initialDate, finalDate]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DatePicker
                    key={`initialDate-${selectedDate.initialDate}`}
                    disabled={disabled}
                    disablePast={disablePast}
                    inputFormat={getInputFormat(locale)}
                    label={t('FROM_PAIR_DATE')}
                    open={openInitialDate}
                    value={selectedDate.initialDate}
                    onOpen={handleOpenInitialDate}
                    onClose={handleCloseInitialDate}
                    onChange={(newValue) => {
                        const date = new Date(newValue!);
                        handleChange(date, 'initialDate');
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            autoComplete="off"
                            fullWidth
                            error={Boolean(error)}
                            helperText={error && (error.split('/')[1] ? `${t(error.split('/')[0])} ${error.split('/')[1]}` : t(error))}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    key={`finalDate-${selectedDate.finalDate}`}
                    disabled={!selectedDate.initialDate || disabled}
                    disablePast={disablePast}
                    label={t('TO_PAIR_DATE')}
                    open={openFinalDate}
                    inputFormat={getInputFormat(locale)}
                    value={selectedDate.finalDate}
                    // defaultCalendarMonth={selectedDate.initialDate}
                    onChange={(newValue) => {
                        const date = new Date(newValue!);
                        handleChange(date, 'finalDate');
                    }}
                    onOpen={handleOpenFinalDate}
                    onClose={handleCloseFinalDate}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            error={Boolean(error)}
                            helperText={error && (error.split('/')[1] ? `${t(error.split('/')[0])} ${error.split('/')[1]}` : t(error))}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
});

export default StepperDatePicker;

const getInputFormat = (locale: string) => {
    switch (locale) {
        case 'it':
            return 'DD/MM/YYYY';
        default:
            return 'MM/DD/YYYY';
    }
};
