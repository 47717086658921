import { Box, Typography, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';

// ==============================|| PROFILE MENU ||============================== //

const UserCard = () => {
    const { user } = useAuth();
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */

    return (
        <>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>{user?.tenantName}</Typography>
                <Typography>{user?.userName}</Typography>
            </Box>
        </>
    );
};

export default UserCard;
