import Tag from 'types/tag';
import axios from 'utils/axios';

export default class TagService {
    async getTags(tenantId: string): Promise<Tag[]> {
        try {
            const result = await axios.post<Tag[]>(`/GetTags`, tenantId);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch tag list');
        }
    }

    async getAssetTags(searchString: string, assetId: string, tenantId: string): Promise<Tag[]> {
        try {
            const tagQuery = {
                name: searchString,
                id: assetId,
                tenantId: tenantId
            };
            const result = await axios.post<Tag[]>(`/GetAssetTags`, tagQuery);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch tag asset list');
        }
    }

    async getHalogTags(searchString: string, halogId: string, tenantId: string): Promise<Tag[]> {
        try {
            const tagQuery = {
                name: searchString,
                id: halogId,
                tenantId: tenantId
            };
            const result = await axios.post<Tag[]>(`/GetHalogTags`, tagQuery);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch tag halog list');
        }
    }
}
