import HalogFilter from 'types/filters/halogFilter';
import Halog from 'types/halog';
import { PaginationResult } from 'types/paginationResult';
import axios from 'utils/axios';

export default class HalogService {
    async getHalogList(halogFilter: HalogFilter): Promise<PaginationResult<Halog>> {
        try {
            const body = {
                page: halogFilter.page,
                recordsPerPage: halogFilter.recordsPerPage,
                paired: halogFilter.paired,
                dateFrom: halogFilter.dateFrom,
                dateTo: halogFilter.dateTo,
                searchString: halogFilter.searchString,
                orderBy: halogFilter.orderBy,
                orderAscending: halogFilter.orderAscending,
                tag: halogFilter.tag
            };
            const result = await axios.post<PaginationResult<Halog>>(`/GetHalogList`, body);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch halog list');
        }
    }

    async addHalog(halog: Halog, tenantId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/AddHalog`, {
                id: halog.id,
                name: halog.name,
                identifier: halog.identifier,
                tags: halog.tags,
                tenantId: tenantId
            });
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to add halog');
        }
    }

    async editHalog(halog: Halog): Promise<boolean> {
        try {
            const response = await axios.post(`/EditHalog`, halog);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to edit halog');
        }
    }

    async deleteHalog(halogId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/DeleteHalog`, halogId);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to delete halog');
        }
    }
}
